import React, { useRef, useMemo, useState, useEffect } from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import { Icosahedron, Text } from '@react-three/drei';
import { EffectComposer, Glitch } from '@react-three/postprocessing';
import * as THREE from 'three';
import './App.css';

const NUM_LINES = 30;  // Number of dashed lines
const DASHES_PER_LINE = 45;

const DashedLines = () => {
  const linesRef = useRef([]);

  const lines = useMemo(() => {
      const tempLines = [];
      for (let i = 0; i < NUM_LINES; i++) {
          const yPosition = (Math.random() - 0.5) * 10;
          for (let j = 0; j < DASHES_PER_LINE; j++) {
              const thickness = Math.random() * 0.005 + 0.005;  // Adjust thickness range as desired
              const material = new THREE.MeshBasicMaterial({ color: 0x77a368, opacity: 0.5 });
              const dashStart = -5 + (10 / DASHES_PER_LINE) * j;
              const geometry = new THREE.PlaneGeometry(0.5, thickness);
              const dash = new THREE.Mesh(geometry, material);
              dash.position.set(dashStart, yPosition, 0);
              dash.userData.direction = Math.random() < 0.5 ? 1 : -1;
              dash.position.x += dash.userData.direction * (Math.random() * 5); // I had mistakenly referred to 'line' here
              tempLines.push(dash);
          }
      }
      return tempLines;
  }, []);

  useFrame(() => {
      linesRef.current.forEach((line) => {
          line.position.x += line.userData.direction * 0.05;
          if (line.position.x > 6 && line.userData.direction === 1) {
              line.position.x = -6;
          } else if (line.position.x < -6 && line.userData.direction === -1) {
              line.position.x = 6;
          }
      });
  });

  return lines.map((line, index) => <primitive key={index} object={line} ref={ref => linesRef.current[index] = ref} />);
}

const GreeneryElectricityComponent = () => {
  const meshRef = useRef(null);

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.x += 0.01;
      meshRef.current.rotation.y += 0.005;
    }
  });

  return (
    <>
      <DashedLines />
      <pointLight position={[0, 10, 0]} intensity={1.5} />
      <ambientLight intensity={0.5} />
      <Text 
        position={[0, 1, 0]} 
        fontSize={0.5}
        color="#FFFFFF" 
        anchorX="center" 
        anchorY="middle">
          BYTESAGE is coming soon!
      </Text>
      <Icosahedron ref={meshRef} args={[1, 1]} color="#ffffff">
        <meshStandardMaterial attach="material" color="#ffffff" wireframe />
      </Icosahedron>
      <EffectComposer>
        <Glitch />
      </EffectComposer>
    </>
  );
}

function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
      const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
      }, 1000);
      
      return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
      const difference = +new Date('2023-10-01') - +new Date();
      let timeLeft = {};

      if (difference > 0) {
          timeLeft = {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60)
          };
      }

      return timeLeft;
  }

  return (
      <div className="countdown">
          <div className="time-unit days">
              <span>{timeLeft.days || '0'}</span>
              <label>Days</label>
          </div>
          <div className="separator">:</div>
          <div className="time-unit">
              <span>{String(timeLeft.hours || '0').padStart(2, '0')}</span>
              <label>Hours</label>
          </div>
          <div className="separator">:</div>
          <div className="time-unit">
              <span>{String(timeLeft.minutes || '0').padStart(2, '0')}</span>
              <label>Minutes</label>
          </div>
          <div className="separator">:</div>
          <div className="time-unit">
              <span>{String(timeLeft.seconds || '0').padStart(2, '0')}</span>
              <label>Seconds</label>
          </div>
      </div>
  );
}

function App() {
  return (
    <div className="App">
      <div style={{ width: '100vw', height: '100vh' }}>
        <CountdownTimer />
        <Canvas style={{ background: "#000000" }}>
          <GreeneryElectricityComponent />
        </Canvas>
      </div>
      <footer style={{ position: 'fixed', bottom: '10px', right: '10px', color: 'white', zIndex: 1000 }}>
        © 2023 ByteSage LLC 
      </footer>
    </div>
  );
}

export default App;
